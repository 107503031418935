import React from "react";
import Popup from "reactjs-popup";
import { useState } from "react";
import { useForm } from "./useForm";
import { useRef } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "../network_calls";
import Alert from "react-bootstrap/Alert";
import { set } from "react-ga";
import ReactGA from "react-ga4";




function ShowAlert(alert) {  if (alert.enabled) {
    return (
      <div class="alert">
        <Alert variant={alert.type}>{alert.message}</Alert>
      </div>
    );
  } else {
    return <></>;
  }
}

function GenButton(isLoading, close) {
  if (isLoading == "COMPLETE"){
    return(
        <button
        type="submit"
        class="bg-gray-400 text-white font-bold py-2 px-2 w-50 mb-2 rounded"
        disabled={true}
      >
        Submit
      </button>
    )
  }
  if (isLoading) {
    return (
      <button
        type="submit"
        class="drop-shadow-4xl inline-flex w-50 justify-content-center px-4 py-2 mb-2 font-bold leading-6 rounded text-white bg-blue-500 hover:bg-blue-700 cursor-not-allowed"
        disabled={true}
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Sending...
      </button>
    );
  } else {
    return (
      <button
        type="submit"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 w-50 mb-2 rounded"
        disabled={false}
      >
        Submit
      </button>
    );
  }
}

function BookingFormPopup(props) {
  const initContactForm = {
    name: "",
    email: "",
    notes: "",
    phone: "",
  };
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({
    enabled: false,
    type: null,
    message: null,
  });
  let [contactForm, change, resetContactForm] = useForm(initContactForm);
  const overlayStyle = { background: "rgba(0,0,0,0.5)", overflow: "scroll"};
  const arrowStyle = { color: "#000" }; // style for an svg element
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    setAlert({...alert, enabled: false});
    setLoading(false);
    setOpen(false);
  }
  function onSubmit(e) {
    // window.dataLayer.push({
    //   event: 'booking_request',
    //   clickId: "booking_request",
    //   eventProps: {
    //       category: "Booking",
    //       action: "Form Submission",
    //       label: "Booking Request",
    //       name: contactForm.name,
    //       email: contactForm.email,
    //       phone: contactForm.phone,
    //   }
    // });

    ReactGA.event({
      category:"Booking",
      action:"booking_request",
      label: "booking_request",
      name: contactForm.name,
      email: contactForm.email,
      phone: contactForm.phone,
     })
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-booking-email", {
        email: contactForm.email,
        notes: contactForm.notes,
        phone: contactForm.phone,
        name: contactForm.name,
        practitioner_id: props.practitioner.practitioner_id,
        appt_time: props.appt_time
      })
      .then((response) => {
        setLoading("COMPLETE");
        setAlert({
          enabled: true,
          type: "success",
          message:
            "We got your email! You should receieve a confirmation email soon. Thank you!",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAlert({
          enabled: true,
          type: "danger",
          message:
            "Our bad, something went wrong. Please try again later or email us directly at support@physiobooking.ca",
        });
      });
    resetContactForm();
  }

  function formatPhoneNumber(value) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ')-' + match[2] + '-' + match[3];
    }
    return value;
  }

  const onPhoneChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    change({target: {name: e.target.name, value: formattedValue}});
  }


  return (
    <div>
      <Popup
        onClose={closeModal}
        trigger={
          <button className="button bg-blue-500 hover:bg-blue-700 rounded px-4 py-2 text-white font-bold shadow-md">
            {props.name}
          </button>
        }
        {...{
          overlayStyle,
          arrowStyle,
        }}
        modal
      >
        {(close) => (
          <div class="bg-white py-4 px-4 h-150vh overflow-scroll items-center">
            <button
                  type="button"
                  onClick={close}
                  class="close bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                >
                  <span class="sr-only">Close menu</span>
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
            <div class="flex items-center justify-center">
              <div class="mx-auto w-full">
                
                <div class="hero">
                  <h1 class="font-bold text-blue-400">Confirm Your Booking</h1>
                </div>
                <p>
                 Confirm your booking at <b>{new Date(props.appt_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</b> on <b>{new Date(props.appt_time).toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' })}</b> with {props.practitioner.practitioner_name}
                </p>
                <Form id="booking_form" onSubmit={onSubmit}>
                    <div class="mb-3">
                        <label
                        for="name"
                        class="mb-3 block text-base font-medium text-[#07074D]"
                        >
                        Name
                        </label>
                        <input
                        type="text"
                        name="name"
                        id="name"
                        placeholder="Doug Dimmadome"
                        required={true}
                        value={contactForm.name}
                        onChange={change}
                        class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                        />
                  </div>
                  <div class="mb-3">
                    <label
                      for="email"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Email Address
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@domain.com"
                      required={true}
                      value={contactForm.email}
                      onChange={change}
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>

                  <div class="mb-3">
                    <label
                      for="phone"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      name="phone"
                      id="phone"
                      placeholder="(123)-456-7890"
                      pattern="\(?([0-9]{3})\)?-([0-9]{3})-([0-9]{4})"
                      required={true}
                      value={contactForm.phone}
                      onChange={onPhoneChange}
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>

                  
                  <div class="mb-3">
                    <label
                      for="notes"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Message
                    </label>
                    <textarea
                      rows="4"
                      name="notes"
                      id="notes"
                      placeholder="What is the reason for your visit?"
                      required={true}
                      value={contactForm.notes}
                      onChange={change}
                      class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    ></textarea>
                  </div>

                  <div class="mb-3">
                    {ShowAlert(alert)}
                    <div class="flex flex-row">
                      <div className="w-50">{GenButton(loading)}</div>

                    </div>

                  </div>

                </Form>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default BookingFormPopup;
