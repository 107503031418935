import axios from "axios";
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // dev code
  axios.defaults.baseURL = "http://localhost:8004";
} else {
  // production code
  axios.defaults.baseURL = "https://api-prd.physiobooking.ca";
}

export default axios;
