
function Footer(props) {
    
    return (
        <div>
            <footer class="bg-white rounded-lg shadow m-4 dark:bg-gray-800">
    <div class="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
      <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2024 <a href="https://physiobooking.ca/" class="hover:underline">Physiobooking</a>. All Rights Reserved.
    </span>
    <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
        <li>
            <a href="/blog" class="hover:underline me-4 md:me-6">Blog</a>
        </li>
        <li>
            <a href="/blog/about" class="hover:underline me-4 md:me-6">About</a>
        </li>
        <li>
            <a href="mailto:support@physiobooking.ca" class="hover:underline">Email</a>
        </li>
    </ul>
    </div>
</footer>
        </div>
    )
}

export default Footer;