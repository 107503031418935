import "./App.css";
import Home from "./pages/Home";
import ReactGA from "react-ga4";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Practitioner from "./components/Practitioner";
import PatientBookingForm from "./components/PatientBookingForm";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import TagManager from 'react-gtm-module'


ReactGA.initialize("G-NPQQPHE7YL");
const tagManagerArgs = {
  gtmId: 'GTM-WHZH3VLQ'
}
TagManager.initialize(tagManagerArgs)




function App() {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  return(
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <div className="flex flex-col min-h-screen">
    <Nav />
    <div class="flex-1">
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/practitioner/:practitioner_id" element={<Practitioner />} />
          <Route path="/patient-booking" element={<PatientBookingForm />} />
        </Routes>
      </Router>
    </div>
    </div>
    <Footer/>
  
  </LocalizationProvider>
  )

  
}

export default App;
