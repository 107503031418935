import logo from "../pages/images/physiobooking_logo.png";
import ContactFormPopup from "./ContactFormPopUp";
import { useState } from "react";

function Nav(props) {
  const [isOpen, setIsOpen] = useState(false);
  return (

    <nav class="bg-white pb-2 px-2">
  <div class="flex flex-wrap items-center justify-between mx-auto ">
    <a href="/"><img class="h-11" src={logo} alt="physiobooking logo" /></a>
    <button onClick={() => setIsOpen(!isOpen)} type="button" class="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="navbar-default" aria-expanded="true">
        <span class="sr-only">Open main menu</span>
        <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15"/>
        </svg>
    </button>
    <div className={`${isOpen ? "" : "hidden"} w-full md:block md:w-auto`} id="navbar-default">
      <ul class="font-medium flex flex-col p-4 md:p-0 mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
        <li>
        <a class="text-gray-600 no-underline hover:text-blue-400" href="/blog/about">About</a>
        </li>
        <li>
        <a class="text-gray-600 no-underline hover:text-blue-400" href="/blog/">Blog</a>
        </li>
        <li>
          <a class="text-gray-600 no-underline hover:text-blue-400" href="patient-booking">Patient Booking</a>
        </li>
        <li>
          <ContactFormPopup />
        </li>
      </ul>
    </div>
  </div>
</nav>
  );
}

export default Nav;
