import Form from "react-bootstrap/Form";
import { Row } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Container } from "react-bootstrap";
// import { useGeolocation } from "rooks";
import { useEffect } from "react";
import { useState } from "react";
import axios from "../network_calls";
import useForm from "./useForm";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';




// import "react-select/dist/react-select.css";
// import "react-virtualized/styles.css";
// import "react-virtualized-select/styles.css";
import ReactGA from "react-ga4";
// import Datepicker from "tailwind-datepicker-react"
// import VirtualizedSelect from "react-virtualized-select";
import Button from 'react-bootstrap/Button';


const currDate = new Date();
const futureDate = new Date();
futureDate.setDate(currDate.getDate() + 10);
const options = {
	maxDate: futureDate,
	minDate: currDate,
  theme: {

  }
	
}


// Then import the virtualized Select HOC

function DropDownList(data) {
  const dropDownList = data.map((rest) => (
    <option value={[rest.longitude, rest.latitude]}>{rest.city}</option>
  ));
  return dropDownList;
}

function DropDownSelect(data) {
  let outputs = [];
  const dropDownList = data.map((rest) =>
    outputs.push({ label: rest.name, value: rest.value })
  );
  return outputs;
}

function GenButton(isLoading) {
  if (isLoading) {
    return (
      <button
        type="submit"
        class="drop-shadow-4xl inline-flex w-100 justify-content-center items-center px-4 py-2 font-bold leading-6 text-sm shadow rounded-md text-white bg-blue-500 hover:bg-blue-700 transition ease-in-out duration-150 cursor-not-allowed sm:w-2"
        disabled={true}
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Searching...
      </button>
    );
  } else {
    return (
      <button
        type="submit"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-3 px-2 w-100 rounded sm:w-2"
        disabled={false}
      >
        Go
      </button>
      
    );
  }
}

function ShowAlert(alert) {
  const variant = alert.type ? alert.type : "danger";
  if (alert.show) {
    return (
      <div class="alert">
        <Alert variant={variant}>
          {alert.msg}
        </Alert>
      </div>
    );
  } else {
    return <></>;
  }
}



function MapInput({ setMarkers }) {
  const [show_date, setShowDate] = useState(false)
  const currDate = dayjs()
  const [selectedDate, setSelectedDate] = useState(currDate);
  const handleChange = (date) => {
    setSelectedDate(date)

  }
  const handleClose = (state) => {
    setShowDate(state)
  }

  const [canadian_city, setCanadianCity] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({show:false, msg:"Something went wrong! Please ensure your location is valid or try again later."});
  // let geoObj = useGeolocation();
  function LoadingMessage(selected_item) {
    if (selected_item && selected_item !== "" && items.length > 0) {
      const name = items.filter((val) => val.id == selected_item)[0];
      return <p class="loading-message">Finding PTs Near you...</p>;
    } else <></>;
  }

  // useEffect(() => {
  //   const searchParams = new URLSearchParams(window.location.search);
  //   const restaurant = searchParams.get("restaurant");
  //   const item = searchParams.get("item");

  //   if (restaurant && item && canadian_city) {
  //     setLoading(true);

  //     let valid_restaurant = false;
  //     let valid_item = false;
  //     canadian_city.forEach(function (restItem) {
  //       if (restItem.value === restaurant) {
  //         valid_restaurant = true;
  //       }
  //     });
  //     if (valid_restaurant) {
  //       setLoading(true);
  //       axios
  //         .get("/get-items", { params: { restaurant: restaurant } })
  //         .then((response) => {
  //           setItems(response.data);
  //           console.log(response.data);
  //           response.data.forEach(function (foodItem) {
  //             if (foodItem.id === item) {
  //               console.log("TRUE", foodItem.name);
  //               valid_item = true;
  //             }
  //           });
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setItems([]);
  //           valid_item = false;
  //         });
  //     }

  //     if (
  //       geoObj &&
  //       geoObj.isError === true &&
  //       !valid_item &&
  //       !valid_restaurant
  //     ) {
  //       setLoading(false);
  //     }
  //     if (geoObj && geoObj.isError === false) {
  //       // queryForm.restaurant = restaurant;
  //       // queryForm.item = item;
  //       queryForm.zip_code = "📍 Current Location";
  //       localStorage.setItem("USER_LAT", geoObj.lat.toString());
  //       localStorage.setItem("USER_LNG", geoObj.lng.toString());
  //       axios
  //         .get("/geojson-features", {
  //           params: {
  //             store: restaurant,
  //             item: item,
  //             lat: geoObj.lat.toString(),
  //             lon: geoObj.lng.toString(),
  //           },
  //         })
  //         .then((response) => {
  //           if (response.data.status === "success") {
  //             setMarkers(response.data);
  //           }
  //           setLoading(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //           setMarkers([]);
  //           setLoading(false);
  //         });
  //     }
  //   }
  // }, [geoObj, canadian_city]);

  useEffect(() => {
    axios
      .get("/canadian-cities/")
      .then((response) => {
        setCanadianCity(response.data);
        //hardcoded calgary for mvp
        const calgary = response.data.find(city => city.city === "Calgary");
        onCityChange({target: {name: "canadian_city", value: calgary.longitude + "," + calgary.latitude}});
        
      })
      .catch((err) => {
        setCanadianCity([]);
      });
  }, []);

  const onCityChange = (e) => {
    change(e);
    // setLoading(true);
    // axios
    //   .get("/get-items", { params: { restaurant: e.target.value } })
    //   .then((response) => {
    //     console.log(response.data);
    //     setItems(response.data);
    //     setLoading(false);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setItems([]);
    //     setLoading(false);
    //   });
  };

  const initQueryForm = {
    canadian_city: null,
    date: new Date(),
  };
  let [queryForm, change, resetQueryForm] = useForm(initQueryForm);

  function onSubmit(event) {
    event.preventDefault();
    setLoading(true);
    if (queryForm.canadian_city && selectedDate) {
    let urlParams = {
        coords: queryForm.canadian_city,
        date: selectedDate.format(),
      };
      // if (
      //   queryForm.zip_code !== "📍 Current Location" &&
      //   queryForm.zip_code !== ""
      // ) {
      //   urlParams.zip_code = queryForm.zip_code;
      // } else if (localStorage.getItem("USER_LAT")) {
      //   urlParams.lat = localStorage.getItem("USER_LAT");
      //   urlParams.lon = localStorage.getItem("USER_LNG");
      // }

      axios
        .get("/search-appointments/", { params: urlParams })
        .then((response) => {
          if (response.data.appointments) {
            if(response.data.appointments.length <= 0){

              setAlert({show:true, msg:"No appointments available for this date. Please try another day."});
              setLoading(false);
              return
            }
            if(response.data.date_adjusted){
              let date = new Date(response.data.date_adjusted);
              let options = { year: '2-digit', month: '2-digit', day: '2-digit' };
              let formattedDate = date.toLocaleDateString('en-US', options);
              setAlert({show:true, type:"warning", msg:"No results were found on searched date. Updated date to " + formattedDate});
              setSelectedDate(dayjs(response.data.date_adjusted));
              setMarkers(response.data.appointments);
              setLoading(false);
              return
            }
            setMarkers(response.data.appointments);
          }
          setLoading(false);
          setAlert({...alert, show:false});
        })
        .catch((err) => {
          setMarkers([]);
          setLoading(false);
          setAlert({...alert, show:true});
        });

      const location_label = urlParams.zip_code
        ? urlParams.zip_code
        : urlParams.lat + "," + urlParams.lon;

      // ReactGA.event({
      //   category: "Submit Query",
      //   action: queryForm.canadian_city,
      //   label: queryForm.item, // optional
      //   value: location_label, // optional, must be a number
      //   nonInteraction: false, // optional, true/false
      //   transport: "xhr", // optional, beacon/xhr/image
      // });
    } else {
      setAlert({...alert, show:true});
      setLoading(false);
    }
  }

  if (canadian_city) {
    return (
      <div class="map-input">
        <div>
          {/* {LoadingMessage(queryForm.item)} */}

          <Form onSubmit={onSubmit}>
            <Container>
              <div class="grid grid-cols-1 justify-content-center sm:grid-cols-4 gap-4">
                <div class="col-span-1">
                  <Form.Group controlId="form.Name">
                    <Form.Select
                      className="p-3"
                      aria-label="City Name"
                      name="canadian_city"
                      value={queryForm.canadian_city}
                      onChange={onCityChange}
                    >
                      <option>Select a City</option>
                      {DropDownList(canadian_city)}
                    </Form.Select>
                  </Form.Group>
                </div>
                <div class="col-span-1">
                  <Form.Group controlId="form.Name">
                    <div className="flex justify-center items-center">
                      <DatePicker disablePast={true} defaultValue={dayjs()} maxDate={dayjs().add(10, 'day')} value={selectedDate} onChange={(newValue) => setSelectedDate(newValue)}/>
                    </div>
                  </Form.Group>
                </div>
                <div class="col-span-1">{GenButton(loading)}</div>
              </div>
              <Row className="">
                {ShowAlert(alert)}
              </Row>
            </Container>
          </Form>
        </div>
        <div className="spacer"></div>
      </div>
    );
  } else {
    return <div></div>;
  }
}
export default MapInput;
