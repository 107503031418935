import MapView from "../components/MapView";
import MapInput from "../components/MapInput";
import Hero from "../components/Hero";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import CardList from "../components/CardList";
import { useState } from "react";
import hero_image from "./images/hero_image_removebg.png";
import { Helmet } from "react-helmet";
function Home(props) {
  const [markers, setMarkers] = useState([]);

  return (
    <div class="grid grid-cols-7">
        <Helmet>
          <title>Physiobooking</title>
          <meta name="title" content="Physiobooking | Find Physiotherapists near me | Book Appointments Online"/>
          <meta name="description" content="Canada's leading physiotherapy booking platform. Currently operating in Calgary. We offer same day bookings as well as an endorsement system to ensure you find a physiotherapist with the specific skillset you desire. We aim to bring elite pain management to the entire country, one booking at a time."/>
          <meta name="keywords" content="calgary, canada, physiotherapy, pain management, physiotherapist"/>
          <meta name="author" content="Physiobooking" />
          <meta property="og:title" content="Physiobooking | Find Physiotherapists near me | Book Appointments Online"/>
          <meta property="og:description" content="Canada's leading physiotherapy booking platform. Currently operating in Calgary. We offer same day bookings as well as an endorsement system to ensure you find a physiotherapist with the specific skillset you desire. We aim to bring elite pain management to the entire country, one booking at a time." />
          <meta property="og:image" content="https://i.ibb.co/5Gj7R3M/physiobooking-logo.png" />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:title" content="Physiobooking | Find Physiotherapists near me | Book Appointments Online"/>
          <meta name="twitter:description" content="Canada's leading physiotherapy booking platform. Currently operating in Calgary. We offer same day bookings as well as an endorsement system to ensure you find a physiotherapist with the specific skillset you desire. We aim to bring elite pain management to the entire country, one booking at a time." />
          <meta name="twitter:image" content="https://i.ibb.co/5Gj7R3M/physiobooking-logo.png" />
          {/* <meta name="twitter:card" content="summary_large_image" /> */}
        </Helmet>
      <div class="col-start-2 col-end-7">
        <Hero />
      </div>
      
        <div class="col-start-1 col-span-7 md:col-start-2 md:col-span-6 pl-4 pr-4">
            <MapInput setMarkers={setMarkers} />
        </div>

        {markers && markers.length > 0 ? (
          <>
            <div class="col-start-1 col-span-7 md:col-end-5">
              <div class="md:overflow-auto h-full md:h-80vh">
                <CardList practitioners={markers}/>
              </div>
            </div>
            <div class="hidden lg:block md:col-start-5 md:col-end-9">
              <MapView markers={markers} />
            </div>
        </>
      
    ) : (
          <div className="col-start-1 col-end-9 bg-gray-100 pt-4 mt-4">
              <div class="text-center flex flex-col items-center">
                <div class="text-2xl pb-2" >
                  Find immediate care <b class="text-blue-400">today</b>
                </div>
                <div class="text-base text-left w-50">
                Discovering the ideal physiotherapy session has never been easier. With direct collaboration with physiotherapists (PTs), we aim to create a list of every available appointment in Canada.
                <br></br><br></br>
                Simply input your city and desired booking date, and we generate a comprehensive list of top-tier physiotherapists available in your city. This includes <b>same day bookings</b>.
                <br></br><br></br>
                We've just launched in <b>Calgary</b>, with plans to expand across Canada 🇨🇦. Want us in your city sooner? Let us know at support@physiobooking.ca!
                </div>
              </div>
                <div class="flex justify-center pt-4 h-80vh">
                <img class="rounded h-50" src={hero_image}></img>
                </div>
              </div>

        )}    
    </div>

  );
}
export default Home;
