import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import BookingFormPopup from './BookingFormPopup';


function PractitionerCard(props) {


    function handleButtonClick(e){
        console.log("button clicked with ", e.target.value)
    }

    const altImgUrl = "https://cdn-icons-png.flaticon.com/512/6751/6751182.png"



    if (! props.practitioner.practitioner_profile_picture_url.includes("http")){
        props.practitioner.practitioner_profile_picture_url = altImgUrl
    }
    return (

        <div className="rounded shadow-lg bg-white">
            <div id='content' className='p-4'>
                <div className='flex justify-start'>
                    <img className="w-20 rounded object-cover h-20 w-20" src={props.practitioner.practitioner_profile_picture_url} alt="practitioner" />
                    <div class="ml-4">
                        <div className="font-bold text-xl mb-2">{props.practitioner.practitioner_name}</div>
                        <div className="text-gray-700 text-base mb-2">
                            {props.practitioner.practitioner_specialty}
                        </div>
                        <div className='text-gray-700 text-base'>
                            {props.practitioner.practitioner_about ? props.practitioner.practitioner_about.substring(0,100) + "..." : ""}
                        </div>
                    </div>
                    
                </div>
                <div class="py-4 flex flex-col">
                    <div class="text-sm text-gray-700">
                        <p>{props.practitioner.clinic_name}</p>
                    </div>
                    <div class="flex flex-row space-x-2">
                        <div class="button bg-blue-500 hover:bg-blue-700 text-sm rounded px-2 py-2 shadow-md">
                            <a class="text-white no-underline" href={"/practitioner/"+props.practitioner.practitioner_id} target="_blank">Learn more</a>
                        </div>
                        <div class="button bg-blue-500 hover:bg-blue-700 text-sm rounded px-2 py-2 shadow-md">
                            <a class="text-white no-underline" href={"https://maps.google.com/?q="+props.practitioner.address} target="_blank">Directions</a>
                        </div>
                    </div>
                    
                    <div class="pt-4 font-bold">
                        Book Evaluation Now
                    </div>
                    <div class='pt-1 flex flex-row flex-wrap'>
                        {props.practitioner.appointments.map((appointment) =>(
                        <div className="pr-2 py-2">
                            <BookingFormPopup name={new Date(appointment.start_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })} appt_time={appointment.start_time} practitioner={props.practitioner} />
                        </div>
                    
                        ))}
                    </div>
                </div>

            </div>
            

        </div>
    );
}

export default PractitionerCard;
