import PractitionerCard from './PractitionerCard';

function CardList(props) {
  return (
    <div>
        {props.practitioners.map((practitioner) => 
                    <div class='p-2'>
                        <PractitionerCard practitioner={practitioner} />
                    </div>
                )}
    </div>
    )    
}
  



export default CardList;
