import Popup from "reactjs-popup";
import { useState } from "react";
import { useForm } from "./useForm";
import { useRef } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "../network_calls";
import Alert from "react-bootstrap/Alert";
import Creatable from 'react-select/creatable';




function ShowAlert(alert) {  if (alert.enabled) {
    return (
      <div class="alert">
        <Alert variant={alert.type}>{alert.message}</Alert>
      </div>
    );
  } else {
    return <></>;
  }
}

function GenButton(isLoading, closeModal) {
  if (isLoading == "COMPLETE"){
    return(
        <button
        type="submit"
        class="bg-gray-400 text-white font-bold py-2 px-2 w-50 mb-2 rounded"
        disabled={true}
        onClick={closeModal}
      >
        Submit
      </button>
    )
  }
  if (isLoading === true) {
    return (
      <button
        type="submit"
        class="drop-shadow-4xl inline-flex w-50 justify-content-center px-4 py-2 mb-2 font-bold leading-6 rounded text-white bg-blue-500 hover:bg-blue-700 cursor-not-allowed"
        disabled={true}
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Sending...
      </button>
    );
  } else {
    return (
      <button
        type="submit"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 w-50 mb-2 rounded"
        disabled={false}
      >
        Submit
      </button>
    );
  }
}

function EndorsementFormPopup(props) {

  const initEndorsementForm = {
    practitioner_id: "",
    endorsements: [],
    email: "",
  };
  let [loading, setLoading] = useState(false);
  let [endorsements, setEndorsements] = useState([]); 
  let [alert, setAlert] = useState({
    enabled: false,
    type: null,
    message: null,
  });
  let [contactForm, change, resetContactForm] = useForm(initEndorsementForm);
  const overlayStyle = { background: "rgba(0,0,0,0.5)", overflow: "scroll"};
  const arrowStyle = { color: "#000" }; // style for an svg element
  const [open, setOpen] = useState(false);

  const closeModal = () => {
    console.log("trigggered")
    setAlert({...alert, enabled: false});
    setLoading(false);
    setOpen(false);
  }

  const setEndorsementsRow = (endorsements) => {
    change({target: {name: "endorsements", value: endorsements}});
  }

  function onSubmit(e) {
    e.preventDefault();
    setLoading(true);
    if(contactForm.endorsements.length > 0 && contactForm.email ){
        axios
        .post("/endorsements/", {
            email: contactForm.email,
            practitioner_id: props.practitioner.id,
            endorsements: contactForm.endorsements
        })
        .then((response) => {
            setLoading("COMPLETE");
            console.log(response.data)
            setAlert({
            enabled: true,
            type: "success",
            message:
                "We got your endorsements! They are in review and should appear shortly. We may reach out if we have a follow up question about your endorsements. Thank you for your help!",
            });
        })
        .catch((err) =>{
            setLoading("COMPLETE");
            setAlert({
            enabled: true,
            type: "danger",
            message:
                "Our bad, something went wrong. Please try again later or email us directly at support@physiobooking.ca"
            })
    })
    }
    else{
        setLoading(false);
        setAlert({enabled:true, type:"danger", message:"Please fill out all fields before submitting."})
    
    }

    // axios
    //   .post("/send-booking-email", {
    //     email: contactForm.email,
    //     notes: contactForm.notes,
    //     phone: contactForm.phone,
    //     name: contactForm.name,
    //     practitioner_id: props.practitioner.practitioner_id,
    //     appt_time: props.appt_time
    //   })
    //   .then((response) => {
    //     setLoading(false);
    //     setAlert({
    //       enabled: true,
    //       type: "success",
    //       message:
    //         "We got your email! You should receieve a confirmation email soon. Thank you!",
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //     setLoading(false);
    //     setAlert({
    //       enabled: true,
    //       type: "danger",
    //       message:
    //         "Our bad, something went wrong. Please try again later or email us directly at support@physiobooking.ca",
    //     });
    //   });
    resetContactForm();
  }

    const endorsementsVal = props.endorsements.map((endorsement) =>({value: endorsement.name, label: endorsement.name}));



  return (
    <div>
      <Popup
        open={open}
        onClose={closeModal}
        trigger={
          <button className="button bg-blue-500 hover:bg-blue-700 rounded px-4 py-2 text-white font-bold shadow-md">
            {props.name}
          </button>
        }
        {...{
          overlayStyle,
          arrowStyle,
        }}
        modal
      >
        {(close) => (
          <div class="bg-white py-4 px-4 h-150vh overflow-scroll items-center">
            <button
                  type="button"
                  onClick={close}
                  class="close bg-white rounded-md inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none"
                >
                  <span class="sr-only">Close menu</span>
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
            <div class="flex items-center justify-center">
              <div class="mx-auto w-full">
                
                <div class="hero">
                  <h1 class="font-bold text-blue-400">Endorse {props.practitioner.name} </h1>
                </div>
                <p>
                 Help your PT by endorsing them with a particular issue they helped you with (ie. Neck pain, lower back pain, etc). This will help us recommend them to others with similar issues. Thank you! 😊
                </p>
                <Form onSubmit={onSubmit}>
                    
                  <div class="mb-3">
                    <label
                      for="email"
                      class="mb-2 block text-base font-medium text-[#07074D]"
                    >
                      Email Address
                    </label>
                    <p class="text-sm text-gray-500">used to prevent duplicate entries</p>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      placeholder="example@domain.com"
                      required={true}
                      value={contactForm.email}
                      onChange={change}
                      class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                  </div>

                  <div class="mb-3">
                    <label
                      for="endorsements"
                      class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                      Endorsements
                    </label>
                    <Creatable isMulti isClearable onChange={setEndorsementsRow} options={endorsementsVal} />
                  </div>

                  <div class="mb-3">
                    {ShowAlert(alert)}
                    <div class="flex flex-row">
                      <div className="w-50">{GenButton(loading, closeModal)}</div>

                    </div>

                  </div>

                </Form>
              </div>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
}

export default EndorsementFormPopup;
