import React from "react";
import Popup from "reactjs-popup";
import { useState } from "react";
import { useForm } from "./useForm";
import { useRef } from "react";
import { useEffect } from "react";
import Form from "react-bootstrap/Form";
import axios from "../network_calls";
import Alert from "react-bootstrap/Alert";
import { set } from "react-ga";
import ReactGA from "react-ga4";




function ShowAlert(alert) {  if (alert.enabled) {
    return (
      <div class="alert">
        <Alert variant={alert.type}>{alert.message}</Alert>
      </div>
    );
  } else {
    return <></>;
  }
}

function GenButton(isLoading, close) {
  if (isLoading == "COMPLETE"){
    return(
        <button
        type="submit"
        class="bg-gray-400 text-white font-bold py-2 px-2 w-50 mb-2 rounded"
        disabled={true}
      >
        Submit
      </button>
    )
  }
  if (isLoading) {
    return (
      <button
        type="submit"
        class="drop-shadow-4xl inline-flex w-50 justify-content-center px-4 py-2 mb-2 font-bold leading-6 rounded text-white bg-blue-500 hover:bg-blue-700 cursor-not-allowed"
        disabled={true}
      >
        <svg
          class="animate-spin -ml-1 mr-3 h-5 w-5 text-green-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
        Sending...
      </button>
    );
  } else {
    return (
      <button
        type="submit"
        class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 w-50 mb-2 rounded"
        disabled={false}
      >
        Submit
      </button>
    );
  }
}

function PatientBookingForm(props) {
  const initContactForm = {
    contact_name: "",
    contact_phone: "",
    consultation_type: "",
    contact_notes: "",
    location: "2201 Montréal Rd, Gloucester, ON K1J 0B4, Canada",
    location_name: "Beacon Heights Retirement Residence",
  };
  let [loading, setLoading] = useState(false);
  let [alert, setAlert] = useState({
    enabled: false,
    type: null,
    message: null,
  });
  let [contactForm, change, resetContactForm] = useForm(initContactForm);


  function onSubmit(e) {

    ReactGA.event({
      category:"Booking",
      action:"patient_booking_request",
      label: "patient_booking_request",
      name: contactForm.contact_name,
      phone: contactForm.contact_phone,
     })
    e.preventDefault();
    setLoading(true);
    axios
      .post("/patients/book", {
        contact_name: contactForm.contact_name,
        contact_phone: contactForm.contact_phone,
        consultation_type: contactForm.consultation_type,
        contact_notes: contactForm.contact_notes,
        location: contactForm.location,
        location_name: contactForm.location_name,
      })
      .then((response) => {
        setLoading("COMPLETE");
        setAlert({
          enabled: true,
          type: "success",
          message:
            "We got your information! We will reach out to you shortly with next steps. Thank you for using Physiobooking!",
        });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setAlert({
          enabled: true,
          type: "danger",
          message:
            "Our bad, something went wrong. Please try again later or email us directly at support@physiobooking.ca",
        });
      });
    resetContactForm();
  }

  function formatPhoneNumber(value) {
    const cleaned = ('' + value).replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return '(' + match[1] + ')-' + match[2] + '-' + match[3];
    }
    return value;
  }

  const onPhoneChange = (e) => {
    const formattedValue = formatPhoneNumber(e.target.value);
    change({target: {name: e.target.name, value: formattedValue}});
  }


  return (
    <div>
        <div class="bg-white py-4 px-4 h-150vh overflow-scroll items-center">
       
        <div class="flex items-center justify-center">
            <div class="mx-auto w-full">
            
            <div class="hero">
                <h1 class="font-bold text-blue-400">Request A Physiotherapy Appointment</h1>
                <p class="text-base font-medium text-gray-400">Once you input your request, we will work to match your contact to a physiotherapist in your area as soon as possible.</p>
            </div>

            <Form id="booking_form" onSubmit={onSubmit}>
                <div class="mb-3">
                    <label
                    for="contact_name"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                    Name
                    </label>
                    <input
                    type="text"
                    name="contact_name"
                    id="contact_name"
                    placeholder="Doug Dimmadome"
                    required={true}
                    value={contactForm.contact_name}
                    onChange={change}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                </div>
                
                <div class="mb-3">
                <label
                    for="contact_phone"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                >
                    Phone Number
                </label>
                <input
                    type="tel"
                    name="contact_phone"
                    id="contact_phone"
                    placeholder="(123)-456-7890"
                    pattern="\(?([0-9]{3})\)?-([0-9]{3})-([0-9]{4})"
                    required={true}
                    value={contactForm.contact_phone}
                    onChange={onPhoneChange}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                />
                </div>

                <div class="mb-3">
                <label
                    for="constultation_type"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                >
                    Reason For Consultation
                </label>
                <select
                    name="consultation_type"
                    id="consultation_type"
                    placeholder="Optional. What is the reason for this visit?"
                    required={true}
                    value={contactForm.consultation_type}
                    onChange={change}
                    class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                >
                    <option value="ENDURANCE">Endurance</option>
                    <option value="STRENGTH">Strength</option>
                    <option value="BALANCE">Balance</option>
                    <option value="OTHER">Other</option>
                </select>
                </div>
                
                <div class="mb-3">
                <label
                    for="contact_notes"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                >
                    Notes
                </label>
                <textarea
                    rows="4"
                    name="contact_notes"
                    id="contact_notes"
                    placeholder="Optional. What time is the contact available for a consultation?"
                    required={true}
                    value={contactForm.contact_notes}
                    onChange={change}
                    class="w-full resize-none rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                ></textarea>
                </div>

                <div class="mb-3">
                    <label
                    for="location_name"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                    Location Name
                    </label>
                    <input
                    type="text"
                    name="location_name"
                    id="location_name"
                    required={true}
                    value={contactForm.location_name}
                    onChange={change}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                </div>

                <div class="mb-3">
                    <label
                    for="location"
                    class="mb-3 block text-base font-medium text-[#07074D]"
                    >
                    Location Address
                    </label>
                    <input
                    type="text"
                    name="location"
                    id="location"
                    required={true}
                    value={contactForm.location}
                    onChange={change}
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                    />
                </div>

                <div class="mb-3">
                {ShowAlert(alert)}
                <div class="flex flex-row">
                    <div className="w-50">{GenButton(loading)}</div>

                </div>

                </div>

            </Form>
            </div>
        </div>
        </div>
    </div>
  );
}

export default PatientBookingForm;
