import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from "../network_calls";
import BookingFormPopup from './BookingFormPopup';
import EndorsementFormPopup from './EndorsementFormPopup';
import { Helmet } from 'react-helmet';


function Practitioner(props) {
  const { practitioner_id } = useParams();
  const [practitionerData, setPractitionerData] = useState(null);
  const [endorsementData, setEndorsementData] = useState(null);
  const [practitionerEndorsements, setPractitionerEndorsements] = useState(null);
  useEffect(() => {
    axios.get(`/practitioner/${practitioner_id}`)
      .then(response => {
        setPractitionerData(response.data);
      })
      .catch(error => {
        console.error('Error fetching practitioner data:', error);
      });
  }, [practitioner_id]);

  useEffect(() => {
    axios.get(`/endorsements/`)
    .then(response => {
      setEndorsementData(response.data);
    })

  }, []);

  useEffect(() => {
    axios.get(`/endorsements/practitioner/${practitioner_id}`)
    .then(response => {
      console.log(response.data)
      setPractitionerEndorsements(response.data);
    })

  }, []);

  if(practitionerData){
    const altImgUrl = "https://cdn-icons-png.flaticon.com/512/6751/6751182.png"

    if (!practitionerData.practitioner.profile_picture_url.includes("http")){
      practitionerData.practitioner.profile_picture_url = altImgUrl
    }
    return(
      <div class="p-4 h-full overflow-x-scroll">
        {/* SEO content */}
        <Helmet>
          <title>{practitionerData.practitioner.name + " Physiobooking Profile"}</title>
          <link rel="canonical" href="https://physiobooking.ca/practitioner/3b6b2552-e66b-4997-8400-b5e2c1e0eb44" />
          <meta name="description" content={practitionerData.practitioner.about + "One of Calgary's best physiotherapists."} />
          <meta name="keywords" content={"calgary, physiotherapy, pain management, " + practitionerData.practitioner.name + ", " + practitionerData.practitioner.clinic.name}/>
          <meta name="author" content="Physiobooking" />
          <meta property="og:title" content={practitionerData.practitioner.name + " Physiobooking Profile"} />
          <meta property="og:description" content={practitionerData.practitioner.about + "One of Calgary's best physiotherapists."} />
          <meta property="og:image" content="https://i.ibb.co/5Gj7R3M/physiobooking-logo.png" />
          <meta property="og:url" content={window.location.href} />
          <meta name="twitter:title" content={practitionerData.practitioner.name + " Physiobooking Profile"} />
          <meta name="twitter:description" content={practitionerData.practitioner.about  + "One of Calgary's best physiotherapists."} />
          <meta name="twitter:image" content="https://i.ibb.co/5Gj7R3M/physiobooking-logo.png" />
          {/* <meta name="twitter:card" content="summary_large_image" /> */}
        </Helmet>
        <h1>{practitionerData.practitioner.name}</h1>
        <img className="w-20 rounded object-cover h-40 w-40 shadow-md" src={practitionerData.practitioner.profile_picture_url} alt="practitioner" />
        <p class='pt-2 font-bold'>Practice Information</p>
        <div class='px-4 py-4 border rounded bg-slate-100'>
          <p><b>Specialty: </b>{practitionerData.practitioner.specialty}</p>
          <p><b>Clinic: </b><a href={practitionerData.practitioner.clinic.website}>{practitionerData.practitioner.clinic.name}</a></p>
          <p><b>Address: </b><a href={"https://maps.google.com/?q="+practitionerData.practitioner.clinic.address}>{practitionerData.practitioner.clinic.address}</a></p>
        </div>
        
        <p class="font-bold pt-2">Available Evaluation Appointments Over The Next 5 Days</p>
        <div class='flex flex-row overflow-x-scroll px-4 py-4 border rounded bg-slate-100'>
          {practitionerData.appointments.map((appointment) =>(
            <div className="pr-2 py-2">
              <BookingFormPopup name={new Date(appointment.start_time).toLocaleDateString('en-US', { month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true  })}  appt_time={appointment.start_time} practitioner={practitionerData.practitioner} />
            </div>
          ))}
        </div>
        <p class="font-bold pt-2">Endorsements</p>
        <p class="text-sm gray-200">Patient endorsements that {practitionerData.practitioner.name} has receieved</p>
        <div class="px-4 py-4 border rounded bg-slate-100 flex flex-row overflow-x-scroll space-x-2">
        {!practitionerEndorsements || practitionerEndorsements.length === 0 ? (
            "No endorsements yet"
          ) : (
            practitionerEndorsements.map((endorsement) => (
              <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">{endorsement.endorsement.name} <b class="text-green-700"> +{endorsement.amount}</b></span>

              // <div className=" bg-blue-100 text-gray-800 text-xs me-2 font-medium px-2.5 py-0.5 rounded flex justify-center items-center">
              //   {/* <div class="flex  items-center justify-center flex-row space-x-1"> */}
              //     <p>{endorsement.endorsement.name} +{endorsement.amount}</p>
              //     {/* <p class="pl-1 text-green-600">+</p> */}
              //   {/* </div> */}
              // </div>
            )))
        } 
        </div>
        <p class="pt-2">Have you had an appointment with {practitionerData.practitioner.name}? Click the button below to endorse them. This will help us recommend them to others with similar issues.</p>
        <div>
          <EndorsementFormPopup name='Add Endorsement' practitioner={practitionerData.practitioner} endorsements={endorsementData}/>
        </div>
        {practitionerData.practitioner.about ? (
          <div>
            <p class="pt-2 font-bold">About</p>
            <p class="px-4 py-4 border rounded bg-slate-100">{practitionerData.practitioner.about}</p>
          </div>
        ) : (
          ""
        )}
        

 
      </div>

    );
  }
  else{
    return (
      <div>
        Loading...
      </div>
    );
  }

  
}

export default Practitioner;